<template>
  <el-form ref="form" :model="model" label-position="top">
    <el-row :gutter="20">
      <el-col :sm="12">
        <el-form-item :label="$tc('recurrence')" prop="voucherDetailRecurrence">
          <select-service-recurrence
            v-model="model.voucherDetailRecurrence"
            :label-all="false"
            @change="$emit('change-recurrence', $event)"
          ></select-service-recurrence>
          <div style="text-align:right">
            <small>
              <em> {{ $tc('period') }}: &nbsp; {{ model.voucherDetailDateStart | formatToDate
              }}
              </em> -
              <em>{{ model.voucherDetailDateEnd | formatToDate }}</em>
            </small>
          </div>
        </el-form-item>
      </el-col>
      <el-col :sm="12">
        <el-form-item :label="$tc('due')" prop="voucherDetailDateDue">
          <el-date-picker
            v-model="model.voucherDetailDateDue"
            type="date"
            :expanded="true"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          ></el-date-picker>
          <span class="help">
            <el-tooltip :content="$tc('helpVoucherDateDue')">
              <i class="fad fa-info-circle">
              </i>
            </el-tooltip>
          </span>
        </el-form-item>
      </el-col>
      <el-col v-if="roleIsAdministrator" :span="6">
        <el-form-item :label="$tc('start')" prop="voucherDetailDateStart">
          <el-date-picker
            v-model="model.voucherDetailDateStart"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col v-if="roleIsAdministrator" :span="6">
        <el-form-item :label="$tc('end')" prop="voucherDetailDateEnd">
          <el-date-picker
            v-model="model.voucherDetailDateEnd"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BillPeriodBill',
  props: {
    model: {
      required: true,
      type: Object,
    },
    data() {
      return {}
    },
  },
  computed: {
    ...mapGetters(['setting']),
    roleIsAdministrator: _this => [1, 2].includes(+_this.setting.roleId),
  },
}
</script>
